import { isPlatform } from "@ionic/react";

import { segmentPluginHelper } from "./segmentPluginHelper";
import { logEvent } from "../../../../appV2/lib/analytics";
import { USER_EVENTS } from "../../../../constants";

export function identifyUser(agent: { userId: string }): void {
  if (isPlatform("capacitor")) {
    segmentPluginHelper.capacitorIdentify(agent.userId, {});
  } else {
    if (agent.userId) {
      segmentPluginHelper.webIdentify(agent.userId, {});
    }
  }
}

export async function addDataToIdentity(
  userId: string | undefined,
  payload: Record<string, any>
): Promise<void> {
  if (!userId) {
    return;
  }
  if (isPlatform("ios") || isPlatform("android")) {
    segmentPluginHelper.capacitorIdentify(userId, payload);
  } else {
    segmentPluginHelper.webIdentify(userId, {
      ...payload,
      traits: payload,
    });
  }
}

export function logApiFailureEvent(error: any, shouldThrowError = false): void {
  const isUrlHasQuery = error?.url && error?.url?.indexOf("?") > -1;
  const properties = {
    reason: "Api Failure",
    message: error?.message || `${error}`,
    requestPath: isUrlHasQuery ? error?.url.substring(0, error?.url.indexOf("?")) : error?.url,
    url: error?.url,
    responseUrl: error?.response?.req?.url,
    status: error?.response?.status,
  };
  logEvent(USER_EVENTS.API_FAILURE, properties);
  if (shouldThrowError) {
    throw error;
  }
}

export function logAndThrowApiError(error: any): void {
  logApiFailureEvent(error, true);
}

export const { createSegmentInstance } = segmentPluginHelper;
